@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  background-color: #282c34;
  color: white;
  min-height: 100vh;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: white;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:hover {
  color: white;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.meta-info {
  margin-top: 1rem;
  padding: 1rem;
  width: 70%;
  /* border: 3px solid #282c34;
  border-radius: 5px; */
}

.meta-info .post-title {
  font-size: 2rem;
}

.meta-info .post-tags {
  font-weight: lighter;
  font-size: smaller;
}

.meta-info .post-blurb {
    margin-top: 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.meta-info a {
    color: #405566;
}

.meta-info a:hover {
    color: #282c34;
}

.pinned-footer a {
    color: #799ab4 !important;
}

.pinned-footer a:hover {
    color: #405566 !important;
}

.bg-dank {
    background-color: #405566 !important;
    font-size: small !important;
}

.bg-dank a {
    color: white;
    text-decoration: none;
}

.bg-dank h6 {
    display: inline !important;
}

blockquote {
    background-color: rgba(0, 0, 0, 0.3);
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    min-height: 3rem;
    border-left: 5px solid #282c34;
    border-radius: 10px;
}

.card-title a {
    color: #405566 !important;
}

.card-title a:hover {
    color: #799ab4 !important;
}